import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import { getAuth } from "firebase/auth";
import './header.css';

const Header = () => {

    const authContext = useSelector((state) => state.authContext);
    const isUserLoggedIn = authContext.isUserLoggedIn;
    
    //const auth = getAuth();
    //const user = auth.currentUser;
    //const isUserLoggedIn = !!user;

        return (
            <div id="sticky-wrapper" className="sticky-wrapper">
            <header className="site-navbar py-4 js-sticky-header site-navbar-target shrink">
            <div className="container">
                <div className="d-flex align-items-center">
                    <div className="site-logo">
                        <NavLink to="/" className="d-block">
                            <img src="/images/cfkLogo.png" alt="cfkLogo" className="img-fluid" />
                        </NavLink>
                    </div>  
                    
                    <nav className="site-navigation">
                        <ul className="site-menu main-menu">
                            <li>
                            <NavLink to="/" activeClassName="active-text">Home</NavLink>
                            </li>
                            {/*<li>
                            <a href="/#features" className="nav-link">Features</a>
                            </li>*/}
                            <li className="has-children">
                            <a href="/#courses" className="nav-link">Courses</a>
                            <ul className="dropdown">
                                <li><NavLink to="/courses/pfkl1">Python Level1</NavLink></li>
                            </ul>
                            </li>
                            <li>
                            <a href="/#about" className="nav-link">About Us</a>
                            </li>
                            <li>
                                <NavLink to="/contactus" activeClassName="active-text">Contact Us</NavLink>
                            </li>
                            <li>
                                {!isUserLoggedIn && <NavLink to="/login" activeClassName="active-text">Login</NavLink>}
                                {isUserLoggedIn && <NavLink to="/profile" activeClassName="active-text">Profile</NavLink>}
                            </li>
                        </ul> 
                    </nav>

                    {/*}
                    <div className="ml-auto">
                        <div className="social-wrap">
                        <a href="#"><span className="icon-facebook"></span></a>
                        <a href="#"><span className="icon-twitter"></span></a>
                        <a href="#"><span className="icon-linkedin"></span></a>

                        <a href="#" className="d-inline-block d-lg-none site-menu-toggle js-menu-toggle text-black"><span
                            className="icon-menu h3"></span></a>
                        </div>
                    </div>
                    */}

                </div>
            </div>
            </header>
            </div>
        );
    }


export default Header;
