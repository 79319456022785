import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBatchesFromDB } from '../common/daolayer';
import  * as Constants from '../common/constants'

const GetBatchesDetail = () =>  {
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoadedForFirstTime, setPageLoadedForFirstTime] = useState(true)
    const dispatch = useDispatch();
    
    const getBatchesHandler = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        //call DB
        //let students = await fetchStudentDetailsFromDB(user.uid);
        //fetchBatchesFromDB();
        const batchList = await fetchBatchesFromDB();
        setIsLoading(false);
        // Once the user clicked the button, set page loaded as false
        setPageLoadedForFirstTime(false);
        dispatch({ type: Constants.ACTION_GET_BATCHES,  value: batchList});
    } 
    
    return (
        <div className="row">
            <div className="col-lg-12 mr-auto align-self-center order-2 order-lg-1">
                <div className="col-md-12 form-group">
                    {!pageLoadedForFirstTime && 
                    <h4 className="text-black d-block">
                        Currently all our batches are full. Please contact us to get added into the waitlist.
                    </h4>}

                    {pageLoadedForFirstTime &&
                    <button className="btn btn-primary btn-lg px-5" 
                        onClick={getBatchesHandler}
                        disabled={isLoading}> 
                            Click here to enroll your kid to a course.
                    </button> }
                    {isLoading && <p>Fetching batches data. Please wait ...</p>}
                </div>

            </div>
        </div>
    );
}

export default GetBatchesDetail;
  

  




 