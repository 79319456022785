import React, {Fragment} from 'react';

// Breadcrumms - pass the list of link and text 
export const Breadcrumbs = ({ data = {}}) =>  (
    <div className="custom-breadcrumns border-bottom">
        <div className="container">
            {
                data.map((el, i) => (
                    <Fragment key={i}>
                    <span className="mx-3 icon-keyboard_arrow_right"></span>
                        <a href={el.link}>{el.text}</a>
                    </Fragment>
                ))
            }
        </div>
    </div>
);

// PageHeading
export const PageHeading = ({image, title, description}) =>  (
    <div className="site-section ftco-subscribe-1" style={{backgroundImage:`url(${image})`}}>
    <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-10">
          <p style={{padding:1+'em'}} />
            <h2 className="mb-0">{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
    </div> 
);

