import EnrollCourse from "./enrollCourse";
import { signOutUser } from "../auth/userAuth";
import StudentList from "./studentsList";
import { useSelector, useDispatch } from 'react-redux';
import GetBatchesDetail from "./getBatches";
import  * as Constants from '../common/constants';
import './profile.css'


const Profile = () =>  {
    const batchesDetail = useSelector((state) => state.batches);
    let batchCount =  Object.keys(batchesDetail).length
    const dispatch = useDispatch();

    const signOutHandler = async () => {
        //console.log("Signing out user")
        await signOutUser();
        dispatch({ type: Constants.ACTION_LOGOUT});
    };
    
    return (
        <div className="site-section">
            <div className="container">

                {!!!batchCount && <GetBatchesDetail />}
                {!!batchCount && <EnrollCourse />}

                <hr style={{padding:1+'em'}}/>
                <StudentList />
                <hr style={{padding:1+'em'}}/>

                <button type='button' className="toggle" onClick={signOutHandler}>
                    Click here to Sign out of website. 
                </button>

            </div>
        </div>
    );
}

export default Profile;
  

  




 