import React from 'react';
import ResultComponent from '../components/resultPageComponent';
import Header from '../components/header'
import Footer from '../components/footer'
import {Breadcrumbs, PageHeading} from '../components/components'

import bg from '../images/bg_1.jpg'

// Stateless functional components
const ResultPage = () =>  (
        <div>
            <Header />  
            <PageHeading  image={bg} 
                title=""
                description="" />
            <Breadcrumbs data={[
                {"link":"/", "text": "Home"},
                {"link":"/result", "text": "Result"}
                ]}/>
            <ResultComponent />
            <Footer />
        </div>
)

export default ResultPage;