import HttpClient from './HttpClient'


export const post = ({
    url,
    data,
  }) => {
    const apiClient = HttpClient.create()
    //console.log(url + " Posting values" + JSON.stringify(data, null, 2))
    return apiClient.post(url, data)
  }


