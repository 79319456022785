import React from 'react';

// ToDo: Should be converted to the stateless component like About
export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <p className="mb-4"><img src="/images/cfkLogo.png" alt="cfkLogo" className="img-fluid" /></p>
                        </div>
                        <div className="col-lg-3">
                            <h3 className="footer-heading"><span>Courses</span></h3>
                            <ul className="list-unstyled">
                                <li><a href="/courses/pfkl1">Python Level1</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <h3 className="footer-heading"><span>Links</span></h3>
                            <ul className="list-unstyled">
                                <li><a href="/#about">About</a></li>
                                <li><a href="/contactus">Contact</a></li>
                                <li><a href="/#features">Features</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                            {/*<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->*/}
                            <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com">Colorlib</a></p>
                            <p>Icons made by <a href="https://www.flaticon.com/authors/zlatko-najdenovski" title="Zlatko Najdenovski">Zlatko Najdenovski</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}