
// Stateless functional components
const ContactUsStatic = () =>  (

    <div className="site-section">
        <div className="container">

            <div className="row">
                <div className="col-lg-10 mr-auto align-self-center">
                    <h3>Send us an email at <strong className="text-black">comp4kids.cs@gmail.com</strong></h3> <p className="mb-5">For quicker response, please use any one of given below text as <strong className="text-black">subject</strong> which indicate your best describe your query.</p>
                    <ol className="ul-check primary list-unstyled">
                        <li>Register for Trial class </li> 
                        <li>Need more info for Python course </li> 
                        <li>Register my kid for a course/batch </li> 
                        <li>Join waitlist <i>(New batch announcement)</i></li>
                        <li>Update my profile</li>
                        <li>General Inquiry - &lt;your subject goes here&gt; </li>
                    </ol>
                </div>
            </div>

            <hr style={{padding:1+'em'}}/>

            <div className="row">
                <div class="col-md-8 mb-4">
                    <h3>Flyer - Python Level 1 Course</h3>
                    <a href='/images/flyer2.jpeg' >
                        <img src="/images/flyer2.jpeg" alt="Flyer" class="img-fluid" width="500" height="600" />
                    </a>
                </div>
            </div>

            <hr style={{padding:1+'em'}}/>

            <div className="row">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <h3>Interested in teaching!</h3>
                        <p>Please mail your resume at above email id with subject 'Looking for teaching opportunities' and we will contact you.</p>
                    </div>
                </div>
            </div>


        </div> 
    </div>

)

export default ContactUsStatic;