import { fetchStudentDetailsFromDB } from '../common/daolayer';
import { useState } from 'react';
import StudentTable from './studentTable'
import { useSelector } from 'react-redux';
import './studentsList.css'

const StudentList = () =>  {
    const authContext = useSelector((state) => state.authContext);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchedStudentData, setFetchedStudentData] = useState('');
    const [pageLoadedForFirstTime, setPageLoadedForFirstTime] = useState(true)

    const studentsListHandler = async (event) => {
        event.preventDefault();
    
        //console.log("Calling fetchStudentDetailsFromDB");
        setIsLoading(true);
        let students = await fetchStudentDetailsFromDB(authContext.uid);
        setFetchedStudentData(students)
        setIsLoading(false);
        // Once the user clicked the button, set page loaded as false
        setPageLoadedForFirstTime(false);
    } 
    
    return (
        <div className="row">

            <div className="col-lg-5 mr-auto align-self-center order-2 order-lg-1">
                <h2 className="section-title-underline mb-5">
                    <span>Currently enrolled kids:</span>
                </h2>

                <div className="col-md-12 form-group">
                    <button className="btn btn-primary btn-lg px-5" 
                        onClick={studentsListHandler}
                        disabled={isLoading}> 
                            Click to load the list of enrolled kids.
                    </button>
                    {isLoading && <p>Fetching Data. Please wait ...</p>}
                </div>

                <div className="col-md-12 form-group">
                    {
                        //Only load table if fetchedStudentData is not set and page is not loading
                        !!fetchedStudentData && !isLoading &&
                        <StudentTable studentsList={fetchedStudentData}/>
                    }
                    { 
                        // If the page is loaded for the first time and page is not loading
                        !!pageLoadedForFirstTime && !isLoading &&
                        <p>Please click the above button to see kids that are enrolled.</p>}
                    {
                        // if page is not loading and user has already clicked the above button and 
                        // still no records found
                        !fetchedStudentData && !isLoading && !pageLoadedForFirstTime &&
                        <p> No records found. Please contact us if you think this it is an error. </p>
                    }
                </div>

            </div>
        </div>
    );
}

export default StudentList;
  

  




 