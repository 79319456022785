import React from 'react';
import {Breadcrumbs, PageHeading} from './components'

import bg from '../images/bg_1.jpg'

const CourseDetail = ({title, description, courseTopics}) =>  (
      <div>
        <PageHeading  image={bg} 
                      title={title} />

        <Breadcrumbs data={[
          {"link":"/", "text": "Home"},
          {"link":"/#courses", "text": "Courses"}
        ]}/>

        <div className="site-section">
        <div className="container">
            <div className="row">
                <div className="col-md-6 mb-4">
                  <a href="http://www.amazon.com/dp/B08KZCMB6H">
                    <p>
                        <img src="/images/pfkl1v1.jpg" alt="pfkl" className="img-fluid" />
                    </p>
                    <p>Buy on Amazon. </p>
                    </a>
                    <a href="http://www.amazon.com/dp/B08L9LBR25"> 
                    <p>
                        <img src="/images/pfkl1v2.jpg" alt="pfkl" className="img-fluid" />
                    </p>
                    <p> Buy on Amazon. </p>
                    </a>
                </div>

                <div className="col-lg-5 ml-auto align-self-center">

                  <h2 className="section-title-underline mb-5">
                      <span>Course Details</span>
                  </h2> 

                  <ol className="ul-check primary list-unstyled">
                        <li>Afterschool online classes </li> 
                        <li> Teach coding fundamentals - skill for life.</li>
                        <li>For kids above 8 years </li> 
                        <li><i>Fees:</i> $60 for four classes per month.</li>
                        <li> Register for Trial class. See contact us.</li>
                    </ol>

                  <p>
                    {description}
                  </p>
                  <ul>
                    {
                      courseTopics ? courseTopics.map((item, index) => (
                        <li key={index}> {item.title} 
                          <ul>
                          {
                            item.subtopics.map((subitem, i) => (
                              <li key={i}> {subitem} </li>
                            ))
                          }
                          </ul> 
                        </li>
                      )) : "loading..."
                    }
                  </ul>
                </div>
              </div>

              <div className="row">
                <p> Interested in teaching the course? We are looking for exceptional persons who are passionate about teaching to young kids. Please submit your application through mail on <u>comp4kids.cs@gmail.com</u>
                </p>
              </div>
          </div>
          </div>


      </div>
  );

  export default CourseDetail;
