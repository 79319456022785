import React from 'react';
import { useLocation } from "react-router";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { storeStripeSessionIdInStudentsDB } from '../common/daolayer';

const ResultComponent = () =>  {
    // Look for the parameters
    const { search } = useLocation();
    const query =  React.useMemo(() => new URLSearchParams(search), [search]);
    const paymentRes = query.get('payment')

    const authContext = useSelector((state) => state.authContext);
    //const isUserLoggedIn = authContext.isUserLoggedIn;

    useEffect(() => {
        //console.log("User.uid = ", authContext.uid)
        if(paymentRes === 'success') {
            const kidEmail = query.get('kidmail');
            const sessionId = query.get('session_id');
            storeStripeSessionIdInStudentsDB(authContext.uid, kidEmail, sessionId);
        }
      }, [authContext]);

    return (
        <div className="site-section">
        <div className="container">
            <div className="row justify-content-center">
                { (paymentRes === 'success') && <h2>Thank you for the payment! </h2> }
                { (paymentRes === 'failed') && <h2>Payment failed. Try again or contact, if problem persist. </h2> }
                { !paymentRes && <h2>Something is wrong. You should not be on this page. </h2> }
            </div>
            <div className="row justify-content-center">
                <p> <Link to='/profile'>Click here to go to profile page. </Link></p>
            </div>
        </div>
        </div>
    );
}

export default ResultComponent;
  

  




 