import { getAuth, createUserWithEmailAndPassword, 
      signInWithEmailAndPassword, signOut, 
      updateProfile, sendEmailVerification } from "firebase/auth";

import { userLoggedInHandler, userLogOutHandler } from "./manageAuthContext";

export const signUpUser = async ({email, password, name}) => {
    const auth = getAuth();
    //console.log("Creating user with ", email, password)
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      //console.log(JSON.stringify(userCredential));
      const user = userCredential.user;
      // Update users Name in the profile update
      updateProfile(user, {
        displayName: name
      });
      //console.log("After update profile");
      // send email for verification
      
      //ToDo: Update the template and then enable it
      //sendEmailVerification(user);

      //console.log("after email verification");
      //console.log("Inside signUpUser " + user.uid);
      userLoggedInHandler(user.uid);
      console.log("User successfully registered.")
      return user.uid;
    }
    catch(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("Error while registering the user " + errorMessage);
      alert("Error while registering: " + errorCode);
    }
  return null;
}

export const signIn = async ({email, password}) => {
  console.log("Signing user with ", email, password)
  const auth = getAuth();
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    //console.log("Inside signIn " + user.uid);
    userLoggedInHandler(user.uid);
    console.log("User successfully logged in.")
    return user.uid;
  } catch(error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("Error while logging in the user with email " + errorMessage);
      alert("Error while logging in: " + errorCode + ". Please contact if problem persist.");
  }
  return null;
}

export const signOutUser = async () => {
  const auth = getAuth();
  try {
    await signOut(auth)
    userLogOutHandler();
    console.log("User successfully signed out.")
  }
  catch(error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Error while signing out. " + errorMessage);
    alert("Error while signing out: " + errorCode + ". Please contact if problem persist.");
  }
}


