

export const passwordValidation = (value) => {
  if (value.trim().length < 7 ) {
    return "Value must be at least seven characters long.";
  }

  return null;
} ;

export const nameValidation = (fieldValue) => {
    if (fieldValue.trim() === "") {
      return "Value is required";
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
      return "Invalid characters";
    }
    if (fieldValue.trim().length < 3) {
      return "Value needs to be at least three characters long";
    }
    return null;
  };
  
  
export const emailValidation = email => {
    if (email.trim() === "") {
        return "Email is required";
      }
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return null;
    }
    return "Please enter a valid email";
  };

  export const mobileNumberValidation = number => {
    if (number.trim() === "") {
        return "Phone number is required";
      }
    if(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(number) ) {
        return null;
    }
    return "Enter a valid phone in format XXX-XXX-XXXX.";

  };
