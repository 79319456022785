import  * as Constants from '../common/constants'

export const userLoggedInHandler = (uid) => {
    console.log("Saving uid to local storage " + uid)
    if(!!uid) {
        localStorage.setItem(Constants.LS_USERID, uid);
    }
}

export const userLogOutHandler = () => {
    localStorage.clear();
}

export const refreshAuthContextFromLocalStorage = () => {
    return localStorage.getItem(Constants.LS_USERID);
}