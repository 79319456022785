
import StudentRow from "./studentRow";

const StudentTable = ({studentsList}) =>  (
    <table>
      <thead>
          <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Date Of Birth</th>
          </tr>
      </thead>
      <tbody>
        {Object.keys(studentsList).map((keyName, keyIndex) => (
          <StudentRow
            key={keyIndex}
            name={studentsList[keyName].kidName}
            email={keyName}
            dob={studentsList[keyName].kidDOB}
          />
        ))}
      </tbody>
    </table>
);

export default StudentTable;