import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

// Example of presentational component
class FeatureSummary extends React.Component {
    render() {
      const {title, features} = this.props
        return (
            <div id="features" className="site-section">
            <div className="container">
              <div className="row mb-5 justify-content-center text-center">
                <div className="col-lg-4 mb-5">
                  <h2 className="section-title-underline mb-5">
                    <p style={{padding:1+'em'}} />
                      <span>{title}</span>
                  </h2>
                </div>
              </div>

              <div className="row">
                  {features
                  ? features.map((el) => (
                        <div key={el.key} className="col-lg-4 col-md-6 mb-4 mb-lg-0">      
                          <div className="feature-1 border">
                            <div className="icon-wrapper bg-primary">
                              <span className={el.iconName +" text-white"}></span>
                            </div>
                            <div className="feature-1-content">
                              <h2>{el.name}</h2>
                              <p>{el.description}</p>
                              <p><a href="#about" className="btn btn-primary px-4 rounded-0">Learn More</a></p>
                            </div>
                          </div>
                        </div>
                        ))
                  : "Loading..."}
              </div>
            </div>
          </div>
        )
    }
}

// To check the prop Types
FeatureSummary.propTypes = {
  title: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired
};

const mapStateToProps = state => (
  { 
    title: state.data.features.title,
    features: state.data.features.featureList,
  })

export default connect(mapStateToProps)(FeatureSummary);

