import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import Profile from '../components/profile';
import {Breadcrumbs, PageHeading} from '../components/components'

import bg from '../images/bg_1.jpg'


const ProfilePage = () =>  {

        
   return (
     <div>
        <Header />  
        <PageHeading  image={bg} 
                title="Welcome"
                description="" />
        <Breadcrumbs data={[
                {"link":"/", "text": "Home"},
                {"link":"/profile", "text": "Profile"}
                ]}/>
        <Profile />
        <Footer />
     </div>
   );
}

export default ProfilePage;

