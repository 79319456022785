import React from 'react';

import Header from '../components/header'
import FeatureSummary from '../components/featureSummary'
import CoursesSummary from '../components/coursesSummary'
import About from '../components/about'
import Footer from '../components/footer'


export default class HomePage extends React.Component {
  render() {
      return (
        <div>
            <Header />
            <FeatureSummary />
            <CoursesSummary />
            <About />
            <Footer />
        </div>
      )
  }
}

