import {post} from '../common/HttpRequest'
import  * as Constants from '../common/constants'

const storeInDB = async (values) => {
    try {
      //console.log("Storing in DB: ", values)
      let resp = await post({url: Constants.DBWriteURL, data: {...values}})
      //console.log(resp.data);
      let responseMessage
      if (resp && resp.data && resp.data.result) {
        responseMessage = resp.data.result
      }
      //ToDo -verify Response message is not null
      //console.log("Response message", responseMessage)
      //alert("Form submitted: " + responseMessage);
      return responseMessage;
    } catch (err) {
      let errorMessage
      // Handle Error Here
      if(err.response && err.response.data) {
        errorMessage = err.response.data
      }
      console.error("Error message: " + errorMessage);
      alert("Error: while submitting form. Please try later.");
    }
    // if control reaches here that means the form submission failed
    return null;
}

const readFromDB = async (values) => {
  try {
    //console.log("Reading from DB: ", values)
    let resp = await post({url: Constants.DBReadURL, data: {...values}})
    //console.log("Retrieved Data: ", resp.data);
    return resp.data
  } catch (err) {
    let errorMessage
    // Handle Error Here
    if(err.response && err.response.data) {
      errorMessage = err.response.data
    }
    console.error("Error message: " + errorMessage);
    alert("Error: while reading the data. Please try later.");
  }
  return null
}

export const storeStudentDetailsInDB = async (messageId, passedKidEmail, messageParams, passedSessionId = '') => {
    return storeInDB({ 
        [passedKidEmail] : {
            ...messageParams,
            sessionId: passedSessionId,
            last_updated: new Date()
        },
        collection: Constants.STUDENTS_COLLECTION,
        messageId: messageId
    })
}

export const fetchBatchesFromDB = async () => {
  const batchesData  = await readFromDB({ 
      collection: Constants.BATCHES_COLLECTION,
      messageId: Constants.BATCHES_COLLECTION_DOC
  })
  
  let fileterBatchData = {};
  for (const key in batchesData) {
    //console.log("Printing data")
    //console.log(`${key}: ${batchesData[key].name}, ${batchesData[key].availableSlots}`);
    if(batchesData[key].availableSlots !== 0) {
      fileterBatchData[key] = batchesData[key];
    }
  }
  //console.log("Filtered batch: ", JSON.stringify(fileterBatchData))
  return fileterBatchData;
}

export const fetchStudentDetailsFromDB = async (messageId) => {
  const studentsData  = await readFromDB({ 
      collection: Constants.STUDENTS_COLLECTION,
      messageId: messageId
  })
  /*
  for (const key in studentsData) {
    console.log("Printing data")
    console.log(`${key}: ${studentsData[key].kidName}, ${studentsData[key].kidDOB}`);
  }*/
  return studentsData;
}

export const storeStripeSessionIdInStudentsDB = async (messageId, passedKidEmail, passedSessionId) => {
  const studentsData  = await readFromDB({ 
      collection: Constants.STUDENTS_COLLECTION,
      messageId: messageId
  })

  let kidData = studentsData[passedKidEmail]
  // Call storeStudentDetailsInDB with session id
  storeStudentDetailsInDB(messageId, passedKidEmail, kidData, passedSessionId);
}

