import Header from '../components/header'
import Footer from '../components/footer'

// Stateless functional components
const PathNotFound = () =>  (
        <div>
        <Header />  
            <div className="site-section ftco-subscribe-1">
            <div className="container">
                <div className="row mb-5 justify-content-center text-center">
                    <div className="col-lg-4 mb-5">
                    <h2 className="section-title-underline mb-5">
                    <p style={{padding:2+'em'}} />
                        <span>404 Path not found.</span>
                    </h2>
                    </div>
                </div>
            </div>
            </div>
        <Footer />
        </div>
)

export default PathNotFound;