import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import CourseDetail from '../components/courseDetail'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

class CourseSingle extends React.Component {
  render() {
    const {courseDetails} = this.props
      return (
        <div>
            <Header />
            <CourseDetail title={courseDetails.title}
                      description={courseDetails.description}
                      courseTopics={courseDetails.courseTopics}/>
            <Footer />
        </div>
      )
  }
}

// To check the prop Types
CourseSingle.propTypes = {
  courseDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => (
  { 
    courseDetails: state[props.match.params.courseId],
  })

export default withRouter(connect(mapStateToProps)(CourseSingle));
