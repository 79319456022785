import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import LoginForm from '../components/loginForm'
import {Breadcrumbs, PageHeading} from '../components/components'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import bg from '../images/bg_1.jpg'

class Login extends React.Component {

  render() {
      
    const {login} = this.props

      return (
        <div>
            <Header />
            <PageHeading  image={bg} 
                          title={login.title}
                          description={login.description} />

            <Breadcrumbs data={[
                           {"link":"/", "text": "Home"},
                           {"link":"/login", "text": "Login or Register"}
                         ]}/>

            <LoginForm />

            {/*  Temporary disabled the  login functionality 
            <div className="site-section ftco-subscribe-1">
            <div className="container">
                <div className="row mb-5 justify-content-center text-center">
                    <div className="mb-5">
                    <h2 className="section-title-underline mb-5">
                    <p style={{padding:2+'em'}} />
                        <span>Page under construction. Coming Soon.</span>
                    </h2>
                    </div>
                </div>
            </div>
            </div>*/}

            <Footer />
        </div>
      )
  }
}

// To check the prop Types
Login.propTypes = {
  login: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => (
  { 
    login: state.data.login,
  })

export default connect(mapStateToProps)(Login);

