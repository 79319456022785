import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

// Example of Stateless functional components
const CoursesSummary = ({ title, courses='loading...' }) =>  (
            <div id="courses" className="site-section">
            <div className="container">
                <div className="row mb-5 justify-content-center text-center">
                    <div className="col-lg-6 mb-5">
                    <h2 className="section-title-underline mb-3">
                        <span>{title}</span>
                    </h2>
                    </div>
                </div>
                <div className="col-12">
                    {courses
                    ? courses.map((el) => (
                        <div key={el.id} className="course-1-item">
                            <div className="category">
                                <h3>{el.category}</h3>
                            </div>
                            <div className="course-1-content pb-4">
                                <h2>{el.name}</h2>
                                <div className="rating text-center mb-3">
                                <span className="icon-star2 text-warning"></span>
                                <span className="icon-star2 text-warning"></span>
                                <span className="icon-star2 text-warning"></span>
                                <span className="icon-star2 text-warning"></span>
                                <span className="icon-star2 text-warning"></span>
                                </div>
                                <p className="desc mb-4">{el.description}</p>
                                <p><a href={"courses/"+el.id} className="btn btn-primary rounded-0 px-4">Learn More</a></p>
                            </div>
                        </div>  ))
                    : "Loading..."}
                </div>
            </div>
            </div>
        );

// To check the prop Types
CoursesSummary.propTypes = {
    title: PropTypes.string.isRequired,
    courses: PropTypes.array.isRequired
};
  
const mapStateToProps = state => (
{ 
    title: state.data.courses.title,
    courses: state.data.courses.courseList,
})
  
export default connect(mapStateToProps)(CoursesSummary);
  

  