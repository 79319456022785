// Local storage
export const LS_USERID = 'uid';


//export const DBWriteURL = 'http://localhost:5001/comp-for-kids/us-central1/storeInFirestoreWithMid' 
export const DBWriteURL = 'https://us-central1-comp-for-kids.cloudfunctions.net/storeInFirestoreWithMid'
//export const DBReadURL = "http://localhost:5001/comp-for-kids/us-central1/retrieveDataFromFirestore"
export const DBReadURL = 'https://us-central1-comp-for-kids.cloudfunctions.net/retrieveDataFromFirestore'

//export const SendMessageUrl = "https://us-central1-comp-for-kids.cloudfunctions.net/addMessage"
export const SendMessageUrl = "http://localhost:5001/comp-for-kids/us-central1/addMessage"

//export const StripeCheckoutURL = 'http://localhost:5001/comp-for-kids/us-central1/payment-createCheckout'
export const StripeCheckoutURL = 'https://us-central1-comp-for-kids.cloudfunctions.net/payment-createCheckout'

export const STUDENTS_COLLECTION = 'students';
export const BATCHES_COLLECTION = 'batches';
export const BATCHES_COLLECTION_DOC = 'availableBatches';

export const ACTION_GET_BATCHES = 'GET_BATCHES'
export const ACTION_LOGIN = 'LOGIN'
export const ACTION_LOGOUT = 'LOGOUT'


