import HomePage from './pages/homePage';
import { Switch, Route, Redirect } from 'react-router-dom'
import CourseSingle from './pages/courseSingle';
import PathNotFound from './pages/fileNotFound404';
import ContactUS from './pages/contactus'
import Login from './pages/login'
import ProfilePage from './pages/profilePage';
import ResultPage from './pages/result';
import { useSelector, useDispatch } from 'react-redux';
import  * as Constants from './common/constants'
//import './App.css';

const App = () => {
  const authContext = useSelector((state) => state.authContext);
  const isUserLoggedIn = authContext.isUserLoggedIn;
  const dispatch = useDispatch();

  //console.log("Authecontext 1: " + JSON.stringify(authContext));
  let storedUID = localStorage.getItem(Constants.LS_USERID); //RefreshAuthContextFromLocalStorage();
  if(!isUserLoggedIn && !!storedUID) {
    //console.log("StoredUId: " + storedUID + " ,Dispatcing...")
    dispatch({ type: Constants.ACTION_LOGIN, uid: storedUID });
  }

  /*
  useEffect(() => {
      console.log("Authecontext: " + JSON.stringify(authContext));
      RefreshAuthContextFromLocalStorage();

      /*
      onAuthStateChanged(auth, (user) => {
          console.log("OnAuthStatechnaged")
          if (user) {
            setIsUserLoggedIn(true)
          } else {
            setIsUserLoggedIn(false)
          }
        })
    }, [] );*/

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/courses/:courseId" component={CourseSingle} />
      <Route exact path="/contactus" component={ContactUS} />
      <Route exact path="/result" component={ResultPage} />
      <Route exact path="/login" >
          { !isUserLoggedIn && <Login /> }
          { isUserLoggedIn && <Redirect to='/profile' /> }
      </Route>

      <Route exact path="/profile">
          { isUserLoggedIn && <ProfilePage /> }
          { !isUserLoggedIn && <Redirect to='/login' /> }
      </Route>

      <Route path="*" component={PathNotFound} />
    </Switch>
  );
};

export default App;
