import { useState } from 'react';
import useInput from '../hooks/use-input';
import './loginForm.css'
import {emailValidation, nameValidation, passwordValidation} from '../common/validations'
import {signUpUser, signIn } from '../auth/userAuth'
import { useDispatch } from 'react-redux';
import  * as Constants from '../common/constants'

const LoginForm = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [enteredPassword2, setEnteredPassword2] = useState('');
    const [enteredPassword2IsValid, setEnteredPassword2IsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    let formIsValid = false;
    const dispatch = useDispatch();
    
    const {
        value: parentEmailValue,
        isValid: parentEmailIsValid,
        hasError: parentEmailHasError,
        errorMessage: parentEmailErrorMessage,
        valueChangeHandler: parentEmailChangeHandler,
        inputBlurHandler: parentEmailBlurHandler,
        reset: parentEmailReset,
      } = useInput(emailValidation);

    const {
        value: parentNameValue,
        isValid: parentNameIsValid,
        hasError: parentNameHasError,
        errorMessage: parentNameErrorMessage,
        valueChangeHandler: parentNameChangeHandler,
        inputBlurHandler: parentNameBlurHandler,
        reset: parentNameReset,
      } = useInput(nameValidation);

      const {
        value: pwordValue,
        isValid: pwordIsValid,
        hasError: pwordHasError,
        errorMessage: pwordErrorMessage,
        valueChangeHandler: pwordChangeHandler,
        inputBlurHandler: pwordBlurHandler,
        reset: pwordReset,
      } = useInput(passwordValidation);

    const switchAuthModeHandler = () => {
        setIsLogin((prevState) => !prevState);
    };

    const pword2ChangeHandler = (event) => {
        if (pwordValue !== event.target.value) {
            setEnteredPassword2IsValid(false);
        } else {
            setEnteredPassword2IsValid(true);
        }
        setEnteredPassword2(event.target.value)
    } 

    const submitHandler = async (event) => {
        event.preventDefault();

        if (isLogin) {
            if (parentEmailIsValid && pwordIsValid ) {
                formIsValid = true;
            }
        } 
        else {
            // If registering new user
            if( parentEmailIsValid && pwordIsValid && parentNameIsValid && enteredPassword2IsValid) {
                // checking passwords again for an edge case
                if (pwordValue === enteredPassword2 ) {
                    formIsValid = true;
                } else {
                    setEnteredPassword2IsValid(false)
                }
            }
        } 
            
        if (!formIsValid) {
            alert("Error: Please validate the form.")
            return;
        }
        setIsLoading(true);

        // Ideally this should go into the dispatcher function, 
        let uid = null;
        if (isLogin) {
            uid = await signIn({email:parentEmailValue, password:pwordValue});
        } else {
            uid = await signUpUser({email:parentEmailValue, password:pwordValue, name: parentNameValue});
        }

        setIsLoading(false); 
        //console.log('Submitted!');
        parentEmailReset();
        parentNameReset();
        pwordReset();
        setEnteredPassword2('');

        console.log("UID Value: " + uid);
        if(!!uid) {
            console.log("Dispatching login action 1")
            dispatch({ type: Constants.ACTION_LOGIN, uid: uid });
        }
      };

    const parentNameClasses = parentNameHasError ? 'form-control form-control-lg invalid-input' : 'form-control form-control-lg';
    const parentEmailClasses = parentEmailHasError ? 'form-control form-control-lg invalid-input' : 'form-control form-control-lg';

    return (
        <form onSubmit={submitHandler}>
        <div className="site-section">
        <div className="container">

        <h1 className="row justify-content-center">{isLogin ? 'Login' : 'Register'}</h1>

            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="row">

                        <div className="col-md-12 form-group">
                            <label htmlFor="parentemail">Parent Email (Username)*</label>
                            <input type="email" 
                            id="parentemail" 
                            className={parentEmailClasses} 
                            value={parentEmailValue}
                            onChange={parentEmailChangeHandler}
                            onBlur={parentEmailBlurHandler} required/>
                            {parentEmailHasError && <p className="error-text">{parentEmailErrorMessage}</p>}
                        </div>

                        <div className="col-md-12 form-group">
                            <label htmlFor="pword">Choose Password*</label>
                            <input type="password" 
                            id="pword" 
                            className="form-control form-control-lg" 
                            value={pwordValue}
                            onChange={pwordChangeHandler}
                            onBlur={pwordBlurHandler} required/>
                            {pwordHasError && <p className="error-text">{pwordErrorMessage}</p>}
                        </div>

                        {!isLogin && (
                        <div className="col-md-12 form-group">
                            <label htmlFor="pword2">Re-type Password*</label>
                            <input type="password" 
                            id="pword2" 
                            className="form-control form-control-lg"
                            onChange={pword2ChangeHandler}
                            value={enteredPassword2} required/>
                            {!enteredPassword2IsValid && <p className="error-text">Passwords must match</p>}
                        </div>)}

                        {!isLogin && (
                        <div className="col-md-12 form-group">
                            <label htmlFor="parentName">Parent Name*</label>
                            <input className={parentNameClasses}
                            type="text" 
                            id="parentName" 
                            value={parentNameValue}
                            onChange={parentNameChangeHandler}
                            onBlur={parentNameBlurHandler} required/>
                        {parentNameHasError && <p className="error-text">{parentNameErrorMessage}</p>}
                        </div>)}
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary btn-lg px-5" 
                                disabled={isLoading}>{isLogin ? 'Login' : 'Create Account'}</button>
                            {isLoading && <p>Sending Request ...</p>}
                            <p>
                            <button
                                type='button'
                                className="toggle"
                                onClick={switchAuthModeHandler}
                            >
                                {isLogin ? 'No Account? Click here to create new account.' : 'Click to Login with existing account.'}
                            </button>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
                      
        </div>
        </div>
        </form>
    );
};

export default LoginForm;