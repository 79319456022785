import { createStore } from 'redux'
import { combineReducers } from '@reduxjs/toolkit';
import Data from '../data/data.json';
import pfkl1 from '../data/pfkl1.json';
import  * as Constants from '../common/constants'

const authInitialState = {
                            uid: 0, 
                            isUserLoggedIn: false 
                         };

const authContextReducer = (state = authInitialState, action) => {
    if (action.type === Constants.ACTION_LOGIN) {
        return {
            uid: action.uid, 
            isUserLoggedIn: true 
            };
    }
    if (action.type === Constants.ACTION_LOGOUT) {
        return {
                uid: 0, 
                isUserLoggedIn: false 
            };
        }
    return state;
}

const dataReducer = (state = Data, action) => {
    return state;
}

const pfkl1Reducer = (state = pfkl1, action) => {
    return state;
}

const batchesReducer = (state = {} , action) => {
    if (action.type === Constants.ACTION_GET_BATCHES) {
        //console.log("Called batch reducer: ", action.value)
        return action.value;
    }
    return state;
}

const store = createStore(combineReducers(
                    {
                        authContext: authContextReducer, 
                        data: dataReducer,
                        pfkl1: pfkl1Reducer,
                        batches: batchesReducer
                    }), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

/*
const store = createStore(rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }))

*/

export default store


