import React from 'react';
import ReactDOM from 'react-dom';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
//import './index.css';
import App from './App';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyBaGY0szEhpEPuOejGLvk3n_URCp3lzNPo",
  authDomain: "comp-for-kids.firebaseapp.com",
  databaseURL: "https://comp-for-kids.firebaseio.com",
  projectId: "comp-for-kids",
  storageBucket: "comp-for-kids.appspot.com",
  messagingSenderId: "45177229474",
  appId: "1:45177229474:web:996c03ceaee87d1a5207fb",
  measurementId: "G-VDBMKN41QN"
};

// Initialize Firebase
initializeApp(firebaseConfig);
getAnalytics();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
