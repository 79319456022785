import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

// Example of Stateless functional components
const About = ({ title, description='loading...' }) =>  (
            <div id="about" className="section-bg style-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <h2 className="section-title-underline style-2">
                    <span>
                      {title}
                    </span>
                  </h2>
                </div>
                <div className="col-lg-8">
                  <p className="lead">{description}</p>
                </div>
              </div>
            </div>
          </div>
        );

// To check the prop Types
About.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const mapStateToProps = state => (
  { 
    title: state.data.about.title,
    description: state.data.about.description
  })

export default connect(mapStateToProps)(About);
