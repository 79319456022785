import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
//import ContactUs from '../components/contactus'
import ContactUsStatic from '../components/contactUsStatic';
import {Breadcrumbs, PageHeading} from '../components/components'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import bg from '../images/bg_1.jpg'

class ContactUS extends React.Component {

  render() {
      
    const {contactus} = this.props

      return (
        <div>
            <Header />
            <PageHeading  image={bg} 
                          title={contactus.title}
                          description={contactus.description} />

            <Breadcrumbs data={[
                           {"link":"/", "text": "Home"},
                           {"link":"/contactus", "text": "Contact Us"}
                         ]}/>


            <ContactUsStatic />
            <Footer />
        </div>
      )
  }
}

// To check the prop Types
ContactUS.propTypes = {
  contactus: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => (
  { 
    contactus: state.data.contactus,
  })

export default connect(mapStateToProps)(ContactUS);

