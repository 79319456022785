import { useState } from 'react';
import useInput from '../hooks/use-input';
import {emailValidation, nameValidation, mobileNumberValidation} from '../common/validations'
import { storeStudentDetailsInDB } from '../common/daolayer';
import { getAuth } from "firebase/auth";
import { stripeCheckout } from '../payments/handlePayment'
import { useSelector } from 'react-redux';

const EnrollCourse = () =>  {
    const auth = getAuth();
    const user = auth.currentUser;

    // ToDo: If user == null then throw error or sign out
    const [isLoading, setIsLoading] = useState(false);
    const [enteredDOB, setEnteredDOB] = useState('');
    const [enteredBatchKey, setEnteredBatchKey] = useState('');
    const batchesDetail = useSelector((state) => state.batches);
    //console.log("Batchees: " + JSON.stringify(batchesDetail));
    const enteredDOBChangeHandler = (event) => {
        setEnteredDOB(event.target.value)
    } 

    const enteredBatchNameChangeHandler = (event) => {
        //console.log("Selected batch name ", event.target.value);
        setEnteredBatchKey(event.target.value);
    } 

    const {
        value: kidEmailValue,
        isValid: kidEmailIsValid,
        hasError: kidEmailHasError,
        errorMessage: kidEmailErrorMessage,
        valueChangeHandler: kidEmailChangeHandler,
        inputBlurHandler: kidEmailBlurHandler,
        reset: kidEmailReset,
      } = useInput(emailValidation);

      const {
        value: parentPhoneValue,
        isValid: parentPhoneIsValid,
        hasError: parentPhoneHasError,
        errorMessage: parentPhoneErrorMessage,
        valueChangeHandler: parentPhoneChangeHandler,
        inputBlurHandler: parentPhoneBlurHandler,
        reset: parentPhoneReset,
      } = useInput(mobileNumberValidation);

    const {
        value: kidNameValue,
        isValid: kidNameIsValid,
        hasError: kidNameHasError,
        errorMessage: kidNameErrorMessage,
        valueChangeHandler: kidNameChangeHandler,
        inputBlurHandler: kidNameBlurHandler,
        reset: kidNameReset,
      } = useInput(nameValidation);

    const submitHandler = async (event) => {
        event.preventDefault();

        // ToDo: Check if the user still logged in else redirect to login
        // Could be possible that the user logged out from another tab

        //console.log(JSON.stringify(user));
        if ( !(kidEmailIsValid && kidNameIsValid && parentPhoneIsValid) ) {
            alert("Error: Please validate the form.")
            return;
        }
        setIsLoading(true);
        //
        const returnedMessage = storeStudentDetailsInDB(
            user.uid, 
            kidEmailValue,
            {
                kidName: kidNameValue,
                kidDOB: enteredDOB,
                batchKey: enteredBatchKey,
                parentPhone: parentPhoneValue,
                batchName: batchesDetail[enteredBatchKey].name
            });

        if(!!returnedMessage) {
            alert("Student enrolled successfully.");
        }

        //await stripeCheckout({user_email:user.email, kid_email:kidEmailValue});
        //
        setIsLoading(false); 
        //console.log('Enrollment Submitted!');
        kidNameReset();
        kidEmailReset();
        parentPhoneReset();
        setEnteredDOB('');

        window.location.reload(false);
    }
    
    return (
        <form onSubmit={submitHandler}>
        <div className="row">
            <div className="col-lg-5 mr-auto align-self-center">
                <h2 className="section-title-underline mb-5">
                    <span>Enroll a kid into course</span>
                </h2>
                
                <p><strong className="text-black d-block">Course Name:</strong> Python Level 1</p>
                
                <p>Please enter the Kids details and click enroll.</p>

                <div className="col-md-12 form-group">
                    <label htmlFor="batchname">Select a batch*</label>
                    <select id="batchname" className="form-control form-control-lg"
                    value={enteredBatchKey} onChange={enteredBatchNameChangeHandler}  required>

                    <option key="emptyBatch" value="" />

                    {
                        Object.keys(batchesDetail).map((batchKey) => (
                            <option key={batchKey} value={batchKey}>
                                {batchesDetail[batchKey].name}
                            </option>
                        ))
                    }
                    </select>
                </div>

                <div className="col-md-12 form-group">
                    <label htmlFor="kidname">Kid's Name*</label>
                    <input type="text" id="kidname" 
                    className="form-control form-control-lg" 
                    value={kidNameValue}
                    onChange={kidNameChangeHandler}
                    onBlur={kidNameBlurHandler} required/>
                    {kidNameHasError && <p className="error-text">{kidNameErrorMessage}</p>}
                </div>

                <div className="col-md-12 form-group">
                    <label htmlFor="kidemail">Kid's Email (To be associated with the Course)*</label>
                    <input type="email" id="kidemail" 
                    className="form-control form-control-lg" 
                    value={kidEmailValue}
                    onChange={kidEmailChangeHandler}
                    onBlur={kidEmailBlurHandler} required/>
                    {kidEmailHasError && <p className="error-text">{kidEmailErrorMessage}</p>}
                </div>

                <div className="col-md-12 form-group">
                    <label htmlFor="kiddob">Kid's Date of Birth (Should be 8+)*</label>
                    <input type="date" id="kiddob" 
                        className="form-control form-control-lg" 
                        value={enteredDOB}
                        onChange={enteredDOBChangeHandler}
                        required/>
                </div>

                <div className="col-md-12 form-group">
                    <label htmlFor="kiddob">Parent's Phone number*</label>
                    <input type="text" id="parentnumber"
                        className="form-control form-control-lg"
                        value={parentPhoneValue}
                        onChange={parentPhoneChangeHandler}
                        onBlur={parentPhoneBlurHandler}
                        required/>
                    {parentPhoneHasError && <p className="error-text">{parentPhoneErrorMessage}</p>}
                </div>

                <div className="col-md-12 form-group">
                    <button className="btn btn-primary btn-lg px-5" disabled={isLoading}> 
                        Enroll 
                    </button>
                    {isLoading && <p>Sending Request ...</p>}
                </div>
            </div>
        </div>
        </form>
    );
}

export default EnrollCourse;
  

  




 